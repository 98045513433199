import { useMediaQuery } from '@kaliber/use-media-query'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import { numberRegex } from '/domain/numberInfoSlider'

import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { SliderButtons } from '/features/pageOnly/SliderButtons'

import mediaStyles from '/cssGlobal/media.css'
import styles from './NumberInfoSlider.css'

export function NumberInfoSlider({ items, theme }) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm)
  const { ref: visibilityRef, wasInViewport } = useIsInViewport({ rootMargin: '-25%' })

  const initialSlide = 0
  const interactionTitle = 'number-info-slider'

  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)
  const [reachedEnd, setReachedEnd] = React.useState(true)

  const { sliderRef, instanceRef, isMounted } = useKeenSliderRefs({
    onDetailsChanged: handleDetailsChanged,
    slidesNum: items.length,
    align: false,
    slidesPerView: isViewportSm ? 'auto' : 1,
    origin: 0,
    spacing: 80,
    initialSlide,
    interactionTitle
  })

  const isVisible = isMounted && wasInViewport

  return (
    <section
      ref={visibilityRef}
      data-x='number-info-slider'
      data-style-context={theme ?? null}
      className={styles.component}
    >
      <ContainerXl>
        <div className={styles.sliderContainer}>
          <div ref={sliderRef} className={cx('keen-slider', styles.slider, isVisible && styles.isVisible)}>
            {Boolean(items.length) && items.map(({ number, description }, i) => {
              const isActive = currentSlide === i

              return (
                <div key={i} className={cx(styles.slideContainer, 'keen-slider__slide')}>
                  <div style={{ '--index': i }} className={cx(styles.slideWrapper, isActive && styles.isActive)}>
                    <NumberCard layoutClassName={styles.cardLayout} {...{ number, description }} />
                  </div>
                </div>
              )
            })}
          </div>

          {isMounted && (
            <SliderButtons {...{ interactionTitle, currentSlide, reachedEnd, handleClick }} />
          )}
        </div>
      </ContainerXl>
    </section>
  )

  function handleDetailsChanged(details) {
    const { rel, maxIdx } = details
    const reachedEndNew = !maxIdx || rel === maxIdx

    setCurrentSlide(rel)
    setReachedEnd(reachedEndNew)
  }

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }
}

function NumberCard({ number: numberString, description, layoutClassName }) {
  const [, number, plusIcon] = numberString?.match(numberRegex) ?? []

  return (
    <div className={cx(styles.componentNumberCard, layoutClassName)}>
      <h3 className={styles.number}>
        <span>{number}</span>
        {plusIcon && <span className={styles.plusIcon}>{plusIcon}</span>}
      </h3>

      <p className={styles.description}>{description}</p>
    </div>
  )
}
